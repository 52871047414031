import "./styles.scss";

import React from "react";

const mainClass = "privacy-policy";

const Content = ({ content }) => {
  return (
    <div className={mainClass}>
      <div className="container container--medium">
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </div>
  );
};

export default Content;
