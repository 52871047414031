import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Layout/components/Seo";
import Content from "page_components/privacy-policy/Content";

const PrivacyPolicy = ({ location, data }) => {
  const { pathname } = location;

  const content = data?.privacyPolicy?.nodes?.[0]?.content;

  return (
    <Layout static_header={pathname === "/"} hide_contact_section>
      <Content content={content} />
    </Layout>
  );
};

export const Head = () => <Seo title="Polityka prywatności | Wólczańska 4" />;

export const query = graphql`
  query {
    privacyPolicy: allWpPage(filter: { id: { eq: "cG9zdDoxNjU=" } }) {
      nodes {
        content
      }
    }
  }
`;

export default PrivacyPolicy;
